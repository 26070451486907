import { AccessorFnColumnDef, createColumnHelper } from '@tanstack/react-table'
import { addDays, compareAsc, format } from 'date-fns'
import { FC, useCallback } from 'react'
import { FormattedNumber } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { parseDateISOStringInLocalTimezone } from 'shared'
import { USD } from 'shared/components/Formatting'
import { isNotNullOrUndefined } from 'shared/guards'
import { detailTable } from '../../../components/shared/DataTable/DetailTable'
import { AccountNumberCell } from '../../../components/shared/DetailTables/AccountNumberCell'
import { HeaderContainer } from '../../../components/shared/DetailTables/HeaderContainer'
import { HighlightSearchText } from '../../../components/shared/DetailTables/HighlightSearchText'
import { Icon } from '../../../features/Icons/Icon'
import { downloadAttachment } from '../../../hooks/useDownloadAttachment'
import { useLazyRetrieveCheckQuery } from '../../../store/rdot360AnalyticsApi'
import { IRdot360Account } from '../../../store/rdot360Context'
import { IActivity } from '../../../store/types'
import { useInvestmentsTableStore } from '../../Investments/features/InvestmentsTable'
import { useInvestmentsDetailsUIState } from '../../Investments/InvestmentsDetailsUIState'
import { useActivityDetailUiState } from '../activityDetailsUiState'
import { DescriptionCell } from '../features/Edit/DescriptionCell'
import { activityDetailColumns } from '../shared'

const SymbolCusipCell: React.FC<{
  symbol?: string
  cusip?: string
  search?: string
}> = ({ symbol, cusip, search }) => {
  const navigate = useNavigate()
  const { setSearchText } = useInvestmentsDetailsUIState()
  const { setInvestmentsViewByKey } = useInvestmentsTableStore()

  const onClick = useCallback(
    (value?: string) => {
      navigate('../investments')
      setInvestmentsViewByKey()
      setSearchText(value)
    },
    [navigate, setInvestmentsViewByKey, setSearchText]
  )

  return (
    <div>
      <div
        css={(theme) => [
          detailTable.ellipsis,
          { color: theme.colors.tertiaryBlue1, cursor: 'pointer' }
        ]}
        onClick={() => onClick(symbol)}
      >
        <HighlightSearchText text={symbol || ''} search={search} />
      </div>
      <div css={detailTable.ellipsis}>
        <HighlightSearchText text={cusip || ''} search={search} />
      </div>
    </div>
  )
}

const ActivityDateCell: React.FC<{
  dttrade?: string
  dtsettle?: string
}> = ({ dttrade, dtsettle }) => {
  const tradeDate = dttrade && parseDateISOStringInLocalTimezone(dttrade)
  const settleDate = dtsettle && parseDateISOStringInLocalTimezone(dtsettle)
  return (
    <div>
      {tradeDate && (
        <div css={detailTable.ellipsis}>{format(tradeDate, 'yyyy-MM-dd')}</div>
      )}
      {settleDate && settleDate > new Date() && (
        <div
          css={[detailTable.ellipsis, { fontSize: '10px' }]}
          title={`Settles on ${format(settleDate, 'yyyy-MM-dd')}`}
        >
          Settles on {format(settleDate, 'yyyy-MM-dd')}
        </div>
      )}
    </div>
  )
}

const useLazyRetrieveCheck = () => {
  const [triggerRetrieveCheck] = useLazyRetrieveCheckQuery()

  const trigger = useCallback(
    (accountList: string, dateRange: string, filterBy: string) => {
      const result = triggerRetrieveCheck(
        {
          AccountList: accountList,
          dateRange: dateRange,
          FilterBy: filterBy
        },
        false
      )
      result.unsubscribe()
      return result
    },
    [triggerRetrieveCheck]
  )

  return trigger
}

export const formatValueUSD = (value?: number) =>
  value != null
    ? Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2
      }).format(value)
    : value

const DownloadAttachment: FC<{ data?: IActivity }> = ({ data }) => {
  const triggerRetrieveCheck = useLazyRetrieveCheck()
  const { setIsDownloading } = useActivityDetailUiState()
  const handleDownloadClick = useCallback(async () => {
    setIsDownloading(true)
    const dateFormat = 'yyyy-MM-dd'
    const tradeDate =
      data?.dttrade && parseDateISOStringInLocalTimezone(data?.dttrade)

    const accountList = data?.acct || ''
    const dateRange =
      (tradeDate &&
        [
          'FromTo',
          format(tradeDate, dateFormat),
          format(addDays(tradeDate, 1), dateFormat)
        ].join(',')) ||
      ''
    const filterby = `CheckAmount,${data?.total},CheckNumber,${data?.checknumber}`
    const displayName = [
      accountList,
      tradeDate && format(tradeDate, dateFormat),
      Math.round(data?.total || 0)
    ]
      .filter(isNotNullOrUndefined)
      .join(' ')

    const { data: check } = await triggerRetrieveCheck(
      accountList,
      dateRange,
      filterby
    )

    const checkimage = check?.activities?.[0]?.checkimage

    setIsDownloading(false)
    if (!checkimage) {
      alert(
        'Check image is currently not available or not yet cleared for this transaction.'
      )
      return
    }

    downloadAttachment(checkimage, 'application/pdf', displayName)
  }, [data, setIsDownloading, triggerRetrieveCheck])

  return (
    <div
      title={'Click to download check image.'}
      css={{ display: 'flex', paddingLeft: '5px', justifyContent: 'center' }}
    >
      {data?.hasimage ? (
        <Icon
          type="Check"
          height={20}
          width={20}
          onClick={handleDownloadClick}
        />
      ) : (
        ''
      )}
    </div>
  )
}

const parseDate = (date?: string) => {
  const parsed = date?.trim() && new Date(date)
  if (
    !date ||
    !parsed ||
    isNaN(parsed.valueOf()) ||
    parsed.getFullYear() <= 1970
  ) {
    return
  }

  return parseDateISOStringInLocalTimezone(date)
}

const columnHelper = createColumnHelper<IActivity>()

export const constructColumns = (
  searchText?: string,
  accountLookup?: Record<string, IRdot360Account | undefined>
) =>
  [
    columnHelper.accessor((row) => parseDate(row.dttrade), {
      id: activityDetailColumns.activityDate,
      cell: (props) => (
        <ActivityDateCell
          dtsettle={props.row.original.dtsettle}
          dttrade={props.row.original.dttrade}
        />
      ),
      header: (props) => (
        <HeaderContainer
          title="Activity Date"
          isSorted={props.column.getIsSorted()}
        />
      ),
      size: 40,
      enableGlobalFilter: false,
      sortingFn: (a, b) =>
        a.original.ispending && !b.original.ispending
          ? 1
          : !a.original.ispending && b.original.ispending
          ? -1
          : compareAsc(
              parseDateISOStringInLocalTimezone(a.original.dttrade || ''),
              parseDateISOStringInLocalTimezone(b.original.dttrade || '')
            )
    }),
    columnHelper.accessor((row) => parseDate(row.dtsettle), {
      id: activityDetailColumns.settlementDate,
      enableHiding: true
    }),
    columnHelper.accessor((row) => parseDate(row.dtentry), {
      id: activityDetailColumns.entryDate,
      enableHiding: true
    }),
    columnHelper.accessor((row) => row.acct, {
      id: activityDetailColumns.account,
      cell: (props) => (
        <AccountNumberCell
          accountIdOrKey={props.row.original.key}
          search={searchText}
        />
      ),
      header: (props) => (
        <HeaderContainer
          title="Account"
          isSorted={props.column.getIsSorted()}
        />
      ),
      size: 44
    }),
    columnHelper.accessor(
      (row) => {
        const { preferredNickname } =
          (row.key && accountLookup?.[row.key]) || {}
        return preferredNickname
      },
      {
        id: activityDetailColumns.nickName,
        enableHiding: true,
        size: 10,
        enableGlobalFilter: false
      }
    ),
    columnHelper.accessor(
      (row) => {
        const { registrationtype } = (row.key && accountLookup?.[row.key]) || {}
        return registrationtype
      },
      {
        id: activityDetailColumns.registrationType,
        enableHiding: true,
        size: 10,
        enableGlobalFilter: false
      }
    ),
    columnHelper.accessor((row) => row.trndesc, {
      id: activityDetailColumns.description,
      cell: (props) => (
        <DescriptionCell
          row={props.row.original}
          descriptionValue={props.getValue()}
          searchText={searchText}
        />
      ),
      header: (props) => (
        <HeaderContainer
          title="Description"
          isSorted={props.column.getIsSorted()}
        />
      ),
      size: 125
    }),
    columnHelper.accessor((row) => row.trntype, {
      id: activityDetailColumns.type,
      cell: (props) => (
        <div style={{ display: 'flex', minWidth: 0 }} title={props.getValue()}>
          <div css={[detailTable.ellipsis]}>
            <HighlightSearchText
              text={props.getValue() || ''}
              search={searchText}
            />
          </div>
          {props?.row?.original?.hasimage && (
            <div style={{ minWidth: '15px' }}>
              <DownloadAttachment data={props.row.original} />
            </div>
          )}
        </div>
      ),
      header: (props) => (
        <HeaderContainer title="Type" isSorted={props.column.getIsSorted()} />
      ),
      size: 40
    }),
    columnHelper.accessor((row) => row.secid, {
      id: activityDetailColumns.symbol,
      enableHiding: true,
      size: 10,
      enableGlobalFilter: false
    }),
    columnHelper.accessor((row) => row.cusipnumber, {
      id: activityDetailColumns.cusip,
      enableHiding: true,
      size: 10,
      enableGlobalFilter: false
    }),
    columnHelper.accessor((row) => `${row.secid} ${row.cusipnumber}`, {
      id: activityDetailColumns.symbolCusip,
      cell: (props) => (
        <SymbolCusipCell
          symbol={props.row.original.secid}
          cusip={props.row.original.cusipnumber}
          search={searchText}
        />
      ),
      header: (props) => (
        <HeaderContainer
          title="Symbol / CUSIP"
          isSorted={props.column.getIsSorted()}
        />
      ),
      size: 40
    }),
    columnHelper.accessor((row) => row.units, {
      id: activityDetailColumns.quantity,
      cell: (props) => (
        <div css={[detailTable.ellipsis, detailTable.rightAlign]}>
          {props.getValue() != null ? (
            <FormattedNumber
              value={props.getValue() || 0}
              maximumFractionDigits={2}
              minimumFractionDigits={0}
            />
          ) : (
            '--'
          )}
        </div>
      ),
      header: (props) => (
        <HeaderContainer
          title="Quantity"
          rightAlign={true}
          isSorted={props.column.getIsSorted()}
        />
      ),
      size: 40,
      enableGlobalFilter: false
    }),
    columnHelper.accessor((row) => row.unitprice, {
      id: activityDetailColumns.price,
      cell: (props) => (
        <div css={[detailTable.ellipsis, detailTable.rightAlign]}>
          {props.getValue() != null ? (
            <USD
              value={props.getValue() || 0}
              fractionDigits={2}
              currencySign="standard"
            />
          ) : (
            '--'
          )}
        </div>
      ),
      header: (props) => (
        <HeaderContainer
          title="Price"
          rightAlign={true}
          isSorted={props.column.getIsSorted()}
        />
      ),
      size: 40,
      enableGlobalFilter: false
    }),
    columnHelper.accessor((row) => row.total, {
      id: activityDetailColumns.amount,
      cell: (props) => {
        const value = props.getValue()
        const formattedValue = formatValueUSD(value)
        return (
          <div css={[detailTable.ellipsis, detailTable.rightAlign]}>
            {value != null ? (
              <HighlightSearchText
                text={formattedValue || ''}
                search={searchText}
              />
            ) : (
              '--'
            )}
            {props.row.original.ispending ? '*' : ''}
          </div>
        )
      },
      header: (props) => (
        <HeaderContainer
          title="Amount"
          rightAlign={true}
          isSorted={props.column.getIsSorted()}
        />
      ),
      size: 40,
      sortingFn: (a, b) =>
        (a.original.total || 0) > (b.original.total || 0)
          ? -1
          : (a.original.total || 0) < (b.original.total || 0)
          ? 1
          : 0
    })
  ] as AccessorFnColumnDef<IActivity>[]
