import axios, { Method } from 'axios'
import { format, subYears } from 'date-fns/fp'
import { chunk } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { IApiOptions } from '../shared/contracts/IApiOptions'
import { IOdataResult } from '../shared/contracts/IOdataResult'
import { isNotNullOrEmpty, isNotNullOrUndefined } from '../shared/guards'
import { trackException } from '../shared/services/telemetry'
import { IActivity } from './activity.types'
import { IAdvisorRep } from './dynamics'
import { constructOdataQuery } from './odata'
import { IOdataRequest } from './odata.types'

export interface IOdataBatchRequest {
  method: Method
  url: string
  headers?: Record<string, string>
  body?: any
}

export interface IOdataBatchResponse {
  responses: IOdataBatchResponseItem[]
}

export interface IOdataBatchResponseItem<T = unknown> {
  id: string
  status: number
  headers: Record<string, string>
  body: T
}

const apiVersion = '1.0'
const defaultHeaders = {
  'api-version': apiVersion
}

export const batch = async (
  options: IApiOptions,
  requests: IOdataBatchRequest[],
  requestLimit = 1000
) => {
  const { apiRoot, cancelToken, accessToken } = options
  const newline = '\r\n'
  const batch = `batch_${uuidv4()}`
  const chunks = chunk(requests, requestLimit)

  const parts = chunks.map((chunk) =>
    chunk.map(({ url, method, headers, body }) =>
      [
        `${method} ${url} HTTP/1.1`,
        ...Object.entries(headers || {}).map(
          ([key, value]) => `${key}: ${value}`
        ),
        body && 'Content-Type: application/json',
        body && '',
        body && `${JSON.stringify(body)}`
      ]
        .filter(isNotNullOrUndefined)
        .join(newline)
    )
  )

  const payloads = parts.map((requests) => {
    return [
      ...requests.map((request, i) =>
        [
          `--${batch}`,
          'Content-Type: application/http',
          'Content-Transfer-Encoding: binary',
          `Content-ID: ${i}`,
          '',
          request,
          ''
        ].join(newline)
      ),
      `--${batch}--`,
      ''
    ].join(newline)
  })

  const responses = await Promise.all(
    payloads.map((payload) =>
      axios.post<IOdataBatchResponse>(`${apiRoot}/datahub/$batch`, payload, {
        headers: {
          ...defaultHeaders,
          'Content-Type': `multipart/mixed;boundary=${batch}`,
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'OData-MaxVersion': '4.0',
          'OData-Version': '4.0'
        },
        cancelToken
      })
    )
  )

  const results = responses.flatMap((x) => x.data.responses)
  if (results.some(({ status }) => status < 200 || status >= 400)) {
    const error = Error(
      `Batch request failed ${options.apiRoot} ${requests
        .map((req) => `${req.method} ${req.url}`)
        .join('\n')}`
    )
    trackException(error, {
      source: 'axios-exception-interceptor'
    })
    throw error
  }
  return results
}

export interface INFSProfileSSN {
  createdby?: string
  createddate?: string
  lastupdatedby?: string
  lastupdateddate?: string
  portaluserid?: string
  ssn?: string
}

export interface INFSProfileBase {
  wsportaluserid?: string
  aaduseroid?: string
  emailprimary?: string
  profilejson?: string
  status?: string
  loginid?: string
  partyid?: string
  createdby?: string
  createddate?: string
  lastlogindate?: string
  lastupdatedby?: string
  lastupdateddate?: string
  mfaRegistrationInfo?: string
  invitationDateTime?: string
}

export interface INfsProfile extends INFSProfileBase {
  id?: number
  firstname?: string
  lastname?: string
  role?: string
  fullname?: string
  repcode?: string
  profilepartyid?: string
  profilestatus?: string
  profilewsportaluserid?: string
  profilephone?: string
  ssn?: string
  ssndetail?: INFSProfileSSN
  phones?: string
  mfaPhones?: string
  mfaPhonesAreValid?: boolean
  firsttimelogindate?: string | null
  mfaskipcounter?: number
  appVersion?: string
  nfsUpdateFlag?: boolean
  hasAnyMfaMethods?: boolean
  hasAuthenticatorMfaMethod?: boolean
  isValidProfile?: boolean
  hasValidAzureAdObjectId?: boolean
  isEnrolledInEauth?: boolean
  accounts?: IProfileAccount[]
  accountCount?: number
}

export interface IProfileAccount {
  accountNumber?: string
  accountKey?: string
  accountSourceTypeCd?: string
}

export interface INfsProfileJson {
  profile?: INfsProfileJsonProfile
}

export interface INfsProfileJsonProfile {
  accounts?: INfsProfileJsonAccount[]
  phones?: INfsProfileJsonPhone[]
}

export interface INfsProfileJsonAccount {
  number?: string
  accountregistration?: string
  nickname?: string
  regtypecode?: string
  repcode?: string
}

export interface INfsProfileJsonPhone {
  Phone?: string
  TelType?: string
  smscapable: string
}

export interface IRelatedParty {
  partyId?: string
  partyName?: string
  partyType?: string
  email?: string
  loginDate?: string
  loginId?: string
  portalId?: string
  role?: string
  aadUserOId?: string
}

export const getNfsProfiles = (
  options: IApiOptions,
  request: IOdataRequest
) => {
  const { apiRoot, cancelToken, accessToken } = options

  return axios
    .get<IOdataResult<INfsProfile>>(
      `${apiRoot}/datahub/nfsProfiles?${constructOdataQuery(request, {
        select: request.select ? ['id'] : undefined,
        orderby: request.apply
          ? undefined
          : [
              {
                dataPath: 'id',
                direction: 'asc'
              }
            ]
      })}`,
      {
        headers: { ...defaultHeaders, Authorization: `Bearer ${accessToken}` },
        cancelToken: cancelToken
      }
    )
    .then((x) => x.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export const getNfsProfile = (
  id: string | number,
  options: IApiOptions,
  request?: IOdataRequest
) => {
  const { apiRoot, cancelToken, accessToken } = options

  const query =
    request &&
    constructOdataQuery(request, {
      select: request.select ? ['id'] : undefined,
      orderby: request.apply
        ? undefined
        : [
            {
              dataPath: 'id',
              direction: 'asc'
            }
          ]
    })

  return axios
    .get<INfsProfile>(
      [`${apiRoot}/datahub/nfsProfiles/${id}`, query]
        .filter(isNotNullOrEmpty)
        .join('?'),
      {
        headers: { ...defaultHeaders, Authorization: `Bearer ${accessToken}` },
        cancelToken: cancelToken
      }
    )
    .then((x) => x.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export const updateNfsProfile = (
  options: IApiOptions,
  profile: INfsProfile
) => {
  if (!profile.id) {
    throw new Error('Request must contain a profile id')
  }
  const { apiRoot, cancelToken, accessToken } = options
  return axios
    .patch<INfsProfile>(
      `${apiRoot}/datahub/nfsprofiles/${profile.id}`,
      {
        ...profile,
        id: undefined
      },
      {
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${accessToken}`,
          Prefer: 'return=representation'
        },
        cancelToken: cancelToken
      }
    )
    .then((x) => x.data)
}

export const delinkNfsProfile = (options: IApiOptions, profileId: number) => {
  if (!profileId) {
    throw new Error('Request must contain a profile id')
  }
  const { apiRoot, cancelToken, accessToken } = options
  return axios
    .post<INfsProfile>(
      `${apiRoot}/datahub/nfsprofiles/${profileId}/delink`,
      undefined,
      {
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${accessToken}`,
          Prefer: 'return=representation'
        },
        cancelToken: cancelToken
      }
    )
    .then((x) => x.data)
}

export interface INfsProfileHistory extends INFSProfileBase {
  id?: number
  profileid?: number
}

export const getNfsProfileHistory = (
  options: IApiOptions,
  profileid: number,
  request: IOdataRequest
) => {
  const { apiRoot, cancelToken, accessToken } = options

  return axios
    .get<IOdataResult<INfsProfile>>(
      `${apiRoot}/datahub/nfsProfiles/${profileid}/history?${constructOdataQuery(
        request,
        {
          select: request.select ? ['id', 'profileid'] : undefined,
          orderby: [
            {
              dataPath: 'id',
              direction: 'asc'
            }
          ]
        }
      )}`,
      {
        headers: { ...defaultHeaders, Authorization: `Bearer ${accessToken}` },
        cancelToken: cancelToken
      }
    )
    .then((x) => x.data)
}

export interface INFSPilotFlag {
  loginid: string
  pilotfeaturename: string
  pilotenabled: boolean
}

export const getNfsProfilePilotFlags = (
  options: IApiOptions,
  profileid: number
) => {
  const { apiRoot, cancelToken, accessToken } = options

  return axios
    .get<IOdataResult<INFSPilotFlag>>(
      `${apiRoot}/datahub/nfsProfiles/${profileid}/pilotflags()`,
      {
        headers: { ...defaultHeaders, Authorization: `Bearer ${accessToken}` },
        cancelToken: cancelToken
      }
    )
    .then((x) => x.data)
}

export const updateNfsProfilePilotFlag = (
  options: IApiOptions,
  profileid: number,
  pilotFlag: INFSPilotFlag
) => {
  const { apiRoot, cancelToken, accessToken } = options

  return axios
    .post<INFSPilotFlag>(
      `${apiRoot}/datahub/nfsProfiles/${profileid}/setpilotflag`,
      {
        request: {
          pilotfeaturename: pilotFlag.pilotfeaturename,
          pilotenabled: pilotFlag.pilotenabled
        }
      },
      {
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${accessToken}`,
          Prefer: 'return=representation'
        },
        cancelToken: cancelToken
      }
    )
    .then((x) => x.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export interface IRockitNFSAccount {
  accountNumber?: string
  createdBy?: string
  deleted?: boolean
  lastUpdatedBy?: string
  lastUpdatedDate?: string
}

export const getRockitNfsAccounts = (
  options: IApiOptions,
  request: IOdataRequest
) => {
  const { apiRoot, cancelToken, accessToken } = options

  return axios
    .get<IOdataResult<IRockitNFSAccount>>(
      `${apiRoot}/datahub/rockitNfsAccounts?${constructOdataQuery(request)}`,
      {
        headers: { ...defaultHeaders, Authorization: `Bearer ${accessToken}` },
        cancelToken: cancelToken
      }
    )
    .then((x) => x.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export function getIndividualRockitNfsAccount(
  options: IApiOptions,
  id: string | undefined,
  request?: IOdataRequest
): Promise<IRockitNFSAccount | undefined> {
  const query = request
    ? `?${constructOdataQuery(request, undefined, { $select: '$select' })}`
    : ''
  return axios
    .get<IRockitNFSAccount>(
      `${options.apiRoot}/datahub/rockitNfsAccounts/${id}${query}`,
      {
        headers: { Authorization: `Bearer ${options.accessToken}` }
      }
    )
    .then((x) => x?.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      throw e
    })
}

export const updateRockitNfsAccount = (
  account: IRockitNFSAccount,
  options: IApiOptions
) => {
  if (!account.accountNumber) {
    throw new Error('Request must contain an account number')
  }
  const { apiRoot, cancelToken, accessToken } = options
  return axios
    .patch<IRockitNFSAccount>(
      `${apiRoot}/datahub/rockitNfsAccounts/${account.accountNumber}`,
      {
        ...account,
        accountNumber: undefined
      },
      {
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${accessToken}`,
          Prefer: 'return=representation'
        },
        cancelToken: cancelToken
      }
    )
    .then((x) => x.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export const addRockitNfsAccount = (
  account: IRockitNFSAccount,
  options: IApiOptions
) => {
  const { apiRoot, cancelToken, accessToken } = options
  return axios
    .post<IRockitNFSAccount>(
      `${apiRoot}/datahub/rockitNfsAccounts`,
      {
        ...account,
        accountNumber: account.accountNumber
      },
      {
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${accessToken}`,
          Prefer: 'return=representation'
        },
        cancelToken: cancelToken
      }
    )
    .then((x) => x.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export interface IManagedAccountFeeHistory {
  account?: string
  client?: string
  billDate?: string
  billPeriodStart?: string
  billPeriodEnd?: string
  days?: number
  billType?: string
  billableValue?: number
  managerFee?: number
  managerFeePercent?: number
  rockefellerFeePercent?: number
  rockefellerFee?: number
  clientFee?: number
  clientFeePercent?: number
  advisorName?: string
  productType?: string
  repCode?: string
  productName?: string
  debitAccount?: string
  branch?: string
  billingGroupName?: string
  billableHouseholdValue?: number
}
export const getManagedAccountFeeHistory = (
  options: IApiOptions,
  request: IOdataRequest
) => {
  const { apiRoot, cancelToken, accessToken } = options

  return axios
    .get<IOdataResult<IManagedAccountFeeHistory>>(
      `${apiRoot}/datahub/managedAccountFeeHistory?${constructOdataQuery(
        request
      )}`,
      {
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${accessToken}`
        },
        cancelToken: cancelToken
      }
    )
    .then((x) => x.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export const getManagedAccountFeeBillTypes = (options: IApiOptions) => {
  const { apiRoot, cancelToken, accessToken } = options

  return axios
    .get<IOdataResult<string>>(
      `${apiRoot}/datahub/managedAccountFeeHistory/GetDistinctBillTypes()`,
      {
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${accessToken}`
        },
        cancelToken: cancelToken
      }
    )
    .then((x) => x.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export interface IDiscountShares {
  rcm_rowlabels: string
  rcm_accountnumber?: string
  rcm_fbsishortname?: string
  rcm_tradedate: string
  rcm_action?: string
  rcm_symbol?: string
  rcm_cusip?: string
  rcm_description?: string
  rcm_securitytype?: string
  rcm_sumofcommission?: number
  rcm_commissionconcession?: number
  rcm_min?: number
  rcm_discountsharing: number
  rcm_team: string
  rcm_concession: number
  rcm_clientname?: string
  rcm_allocatedamount?: number
  rcm_assetclass?: string
  rcm_billinggroupname?: string
  rcm_discount?: number
  rcm_floorindicator?: number
  rcm_hhaum?: number
  rcm_productname?: string
  rcm_rockefellerfee?: number
  rcm_supportindicator?: number
}

export const getDiscountShares = (
  options: IApiOptions,
  fromDate?: Date,
  pageSize?: number,
  advisoryFees?: boolean
) => {
  if (!fromDate) {
    fromDate = new Date()
  }
  const base = `${options.apiRoot}/datahub/D365Proxy/rcm_discountsharingorders`
  const start = new Date(fromDate.getUTCFullYear(), 0, 0, 0, 0, 0, 0)
  const end = new Date(fromDate.getUTCFullYear(), 11, 31, 23, 59, 59, 999)
  const query = `$filter=rcm_tradedate ge ${start.toISOString()} and rcm_tradedate le ${end.toISOString()} and rcm_inorout eq 798960000 and rcm_securitytype ${
    advisoryFees ? 'eq' : 'ne'
  } 'Advisory Fees'`

  return axios
    .get<IOdataResult<IDiscountShares>>(`${base}?${query}`, {
      headers: {
        Authorization: `Bearer ${options.accessToken}`,
        Prefer: [
          pageSize ? `odata.maxpagesize=${pageSize}` : undefined,
          `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
        ]
          .filter(isNotNullOrEmpty)
          .join(', ')
      },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data)
}

export const getAdvisorDomainFromDynamics = (options: IApiOptions) => {
  const { apiRoot } = options
  const url = `${apiRoot}/datahub/D365Proxy/rcm_advisorreps`
  const query =
    `select=rcm_advisorrepid,rcm_repid,rcm_name` +
    `&$expand=rcm_OwningTeam($select=name;$expand=businessunitid($select=name;$expand=parentbusinessunitid($select=name;$expand=parentbusinessunitid($select=name;$expand=parentbusinessunitid($select=name))))),` +
    `rcm_PersonalRepFor($select=domainname,fullname,_businessunitid_value,businessunitid),` +
    `rcm_AdvisorRepPoolSplits_PoolRep_rcm_Advi($select=rcm_percentage,rcm_calc_poolrepname,rcm_calc_personalrepname,rcm_calc_poolrepid,rcm_calc_personalrepid;$filter=statecode eq 0)`
  return axios
    .get<IOdataResult<IAdvisorRep>>(`${url}?${query}`, {
      headers: {
        Authorization: `Bearer ${options.accessToken}`,
        Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
      },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data.value)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export interface IRevenueSummaryItem {
  category?: string
  compRevenue: number
  payout: number
  payrollPeriodDate: string
}

export type RevenueSummaryCategoryMode = 'L1' | 'L2'
export interface IGetRevenueSummaryRequest {
  tradeReps: string[]
  recipientReps?: string[]
  catMode?: RevenueSummaryCategoryMode
  toDate?: Date
  fromDate?: Date
}

const formatDateForApi = format(`yyyy-MM-dd'T'HH:mm:ss.SSSxxx`)
export const getRevenueSummary = (
  request: IGetRevenueSummaryRequest,
  options: IApiOptions
) => {
  const { apiRoot, cancelToken, accessToken } = options
  const now = new Date()
  const {
    tradeReps,
    recipientReps,
    catMode = 'L1',
    toDate = now,
    fromDate = subYears(1)(now)
  } = request
  const body = {
    tradeReps,
    recipientReps,
    CatMode: catMode,
    todate: formatDateForApi(toDate),
    fromdate: formatDateForApi(fromDate)
  }
  return axios
    .post<IRevenueSummaryItem[]>(
      `${apiRoot}/datahub/revenue/GetMonthlySummary`,
      body,
      {
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${accessToken}`
        },

        cancelToken: cancelToken
      }
    )
    .then((x) => x.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export type HurdleCompletionType = 'OneAndDone' | 'AllOrNothing'
export type HurdleIntevalType = 'Annual' | 'Quarterly' | 'Monthly'
export type HurdleMetricType =
  | 'T-12 From Hurdle'
  | '3 Months Annualized'
  | 'AUS'
export type HurdlePayoutType = 'Cash' | 'Note'

export interface IHurdle {
  hurdleId?: number
  name?: string
  entityType?: 'Team' | 'Individual'
  entityId?: string
  entityName?: string
  advertisedT12Revenue?: number
  completionType?: HurdleCompletionType
  measurements?: IHurdleMeasurement[]
  createdDate?: string
  createdBy?: string
  notes?: string
  entityStartDate?: string
  accrualStartDate?: string
  term?: number
  probability?: number
}

export interface IHurdleMeasurement {
  measurementId?: number
  targetDate?: string
  intervalOfMeasurement?: HurdleIntevalType
  metrics?: IHurdleMetric[] | IMetricWithProgress[]
}

export interface IHurdleMetric {
  metricId?: number
  metricType?: HurdleMetricType
  metricValue?: number
  payouts?: IHurdlePayout[]
  progressMeasurements?: IHurdleProgressMeasurement[]
  progressMeasurementStatuses?: IHurdleProgressStatus[]
  probability?: IHurdleMetricProbability
}

export interface IHurdlePayout {
  payoutId?: number
  userFullName?: string
  userDomainName?: string
  payoutType?: HurdlePayoutType
  payoutValue?: number
}

export interface IHurdleMetricProbability {
  hurdleId?: number
  metricId?: number
  targetDate?: string
  averageProbable?: number
}

export interface IMetricWithProgress extends IHurdleMetric {
  totalProgress?: IMetricProgress
  trailing6MonthProgress?: IMetricProgress[]
  status?: IHurdleStatus
  targetValue?: number
  advertisedT12?: number
  date?: string
}

export interface IMetricProgress {
  progress?: number
  total?: number
  advertisedT12Revenue?: number
  metricTarget?: number
  currentMonthAnnualized?: number
  currentT12?: number
  periodDate?: Date
  periodStartDate?: Date
  metricType?: HurdleMetricType
}

export interface IHurdleProgressStatus {
  currentPeriodDate?: string
  status?: IHurdleStatus
}

export const createOrUpdateHurdle = (options: IApiOptions, hurdle: IHurdle) => {
  const { apiRoot, cancelToken, accessToken } = options

  const action = hurdle.hurdleId ? axios.patch : axios.post

  return action<IHurdle>(
    `${apiRoot}/datahub/hurdles${hurdle.hurdleId ? `/${hurdle.hurdleId}` : ''}`,
    {
      ...hurdle
    },
    {
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${accessToken}`,
        Prefer: 'return=representation'
      },
      cancelToken: cancelToken
    }
  )
    .then((x) => x.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export const getHurdles = (options: IApiOptions, request: IOdataRequest) => {
  const { cancelToken, accessToken, apiRoot } = options

  const url = `${apiRoot}/datahub/hurdlesExtended`
  return axios
    .get<IOdataResult<IHurdle>>(`${url}?${constructOdataQuery(request)}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      cancelToken: cancelToken
    })
    .then((x) => x.data)
}

export const deleteHurdle = (options: IApiOptions, id: number) => {
  const { apiRoot } = options
  const url = `${apiRoot}/datahub/hurdles`
  return axios
    .delete(`${url}/${id}`, {
      headers: {
        Authorization: `Bearer ${options.accessToken}`
      },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export type IHurdleStatus = 'Achieved' | 'Missed' | 'Pending' | 'Not Applicable'

export interface IHurdleProgressMeasurement {
  hurdleRevenueMeasurementsId?: number
  name?: string
  entityId?: string
  entityName?: string
  divisionName?: string
  advertisedT12Revenue?: number
  intervalofMeasurement?: string
  currentMonthAnnualized?: number
  currentT12?: number
  metricThreeMonthsAnnualized?: number
  metricT12?: number
  numberofMonthsinMeasurementPeriod?: number
  measuredMonths?: number
  lowestMetricProgress?: number
  glMonthClose?: string
  measurementId?: number
  createdDate?: string
  createdBy?: string
  targetDate?: string
  currentPeriodDate?: string
  metricId?: number
  perNumber?: number
  metricType?: HurdleMetricType
  metricMeasurementValue?: number
  metricProgress?: number
  targetMetricValue?: number
  isEndOfMeasurementPeriod?: boolean
  status?: IHurdleStatus
  hurdleDate?: string
  isHurdleAchieved?: boolean
  isMeasurementTracked?: boolean
}

export const getHurdleProgressMeasurements = (
  options: IApiOptions,
  request: IOdataRequest
) => {
  const { cancelToken, accessToken, apiRoot } = options

  const url = `${apiRoot}/datahub/hurdleprogressmeasurements`
  const query = constructOdataQuery(request)

  return axios
    .get<IOdataResult<IHurdleProgressMeasurement>>(`${url}?${query}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      cancelToken: cancelToken
    })
    .then((x) => x.data)
}

export interface IRevenueDashboardRevenueSummary {
  mostRecentlyClosed?: IRevenueDashboardRevenueSummaryItem
  priorYear?: IRevenueDashboardRevenueSummaryItem
  currentYear?: IRevenueDashboardRevenueSummaryItem
  prior3Months?: IRevenueDashboardRevenueSummaryItem[]
  priorYearPrior3Months?: IRevenueDashboardRevenueSummaryItem[]
}

export interface IRevenueDashboardRevenueSummaryItem {
  ytdRevenue?: number
  annualizedRevenue?: number
  t12Revenue?: number
  periodRevenue?: number
  period?: string
}

export interface IGetDasbhoardRevenueSummaryRequest {
  tradeReps: string[]
  recipientReps?: string[]
}

export const getDashboardRevenueSummary = (
  options: IApiOptions,
  request?: IGetDasbhoardRevenueSummaryRequest
) => {
  const { cancelToken, accessToken, apiRoot } = options
  const url = `${apiRoot}/datahub/revenue/GetDashboardSummary`
  return axios
    .post<IRevenueDashboardRevenueSummary>(url, request, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      cancelToken: cancelToken
    })
    .then((x) => x.data)
}

export const getMostRecentClosedMonth = (options: IApiOptions) => {
  const { cancelToken, accessToken, apiRoot } = options

  const url = `${apiRoot}/datahub/hurdles/GetMostRecentClosed()`
  return axios
    .get<{ value: string }>(`${url}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      cancelToken: cancelToken
    })
    .then((x) => x.data?.value)
}

export interface IRevenueDashboardHurdleSummaryItem {
  metricId?: string
  distance?: number
  dateAchieved?: string
  earliestMeasurementDate?: string
  isActive?: boolean
  isHurdleAchieved?: boolean
  measurements?: IHurdleProgressMeasurement[]
}

export interface IDashboardHurdleSummaryRequest {
  repIds?: string[]
  teamIds?: string[]
}

export interface IDashboardHurdleSummaryResponse {
  hurdleCount?: number
  activeHurdleCount?: number
  futureMeasurement?: {
    name?: string
    targetDate?: string
    targetValue?: number
  }
  results?: IRevenueDashboardHurdleSummaryItem[]
}

export const getDashboardHurdleSummary = (
  options: IApiOptions,
  request: IDashboardHurdleSummaryRequest
) => {
  const { cancelToken, accessToken, apiRoot } = options
  const url = `${apiRoot}/datahub/HurdleProgressMeasurements/GetMeasurementSummary`
  return axios
    .post<IDashboardHurdleSummaryResponse>(
      url,
      { request },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        cancelToken: cancelToken
      }
    )
    .then((x) => x.data)
}

export const rerunMeasurements = (options: IApiOptions) => {
  const { apiRoot, cancelToken, accessToken } = options
  return axios
    .post(
      `${apiRoot}/datahub/HurdleProgressMeasurements/RerunMeasurements`,
      undefined,
      {
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${accessToken}`
        },
        cancelToken: cancelToken
      }
    )
    .then((x) => x)
}

export const getHurdlesWithProgress = (
  options: IApiOptions,
  request: IOdataRequest
) => {
  const { cancelToken, accessToken, apiRoot } = options

  const url = `${apiRoot}/datahub/hurdles`

  const query = constructOdataQuery({
    ...request,
    expand: [
      ...(request.expand || []),
      'measurements($expand=metrics($expand=progressMeasurements,progressMeasurementStatuses($select=status,currentPeriodDate)))'
    ]
  })

  return axios
    .post<IOdataResult<IHurdle>>(`${url}/$query`, query, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'text/plain'
      },
      cancelToken: cancelToken
    })
    .then((x) => x.data)
}

export interface IHurdleProgressMeasurementStatus {
  metricId: number
  status: IHurdleStatus
}

export const updateStatus = (
  options: IApiOptions,
  id: number,
  status: IHurdleStatus
) => {
  const { apiRoot, cancelToken, accessToken } = options
  return axios
    .post<IHurdleProgressMeasurementStatus>(
      `${apiRoot}/datahub/hurdleprogressmeasurements/${id}/setstatus`,
      {
        status: status
      },
      {
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${accessToken}`,
          Prefer: 'return=representation'
        },
        cancelToken: cancelToken
      }
    )
    .then((x) => x.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export interface IReportSearchRequest {
  accounts?: string[]
  startDate?: Date
  endDate?: Date
}

export interface IReportSearchResult {
  netTotal: number | undefined
  totalOptionsPremium: number | undefined
  totalExpense: number | undefined
  totalIncome: number | undefined
  expense: {
    category: string
    value: number
  }[]
  income: {
    category: string
    value: number
  }[]
}

export const reportSearchRequest = (
  options: IApiOptions,
  request?: IReportSearchRequest
) => {
  const { apiRoot, cancelToken, accessToken } = options
  return axios
    .post<IReportSearchResult>(
      `${apiRoot}/datahub/AccountIncomeAndExpenses/GetAccountIncomeExpenseSummary`,
      {
        request
      },
      {
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${accessToken}`,
          Prefer: 'return=representation'
        },
        cancelToken: cancelToken
      }
    )
    .then((x) => x.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export interface ICDMv2Party {
  partyID?: string
  lastName?: string
  firstName?: string
  partyName?: string
  suffix?: string
  dateOfBirth?: string
  dateOfDeath?: string
}
export interface IRMDDetailsResult {
  rr1?: string
  accountNumber?: string
  currentRMDYear?: string
  estRMDAmount?: number
  estRMDAmountRemain?: number
  rmdMandWithDate?: string
  rmdAmtTaken?: number
  availToWithdrawCash?: number
  ageOfPrimAccntHolder?: number
  currentDistYear?: string
  totalDistAmt?: number
  lifeExpectancyCode?: string
  lifeExpectancyValue?: number
  prdicDistPlanIndicator?: string
  expctedRemainPrdicPymtAmt?: number
  priorFairMktValYr?: string
  priorYrFairMktVal?: number
  priorYrTotalDistAmt?: number
  dateOfDeathPrimary?: string
  dobOfPrimAcctHolder?: string
  totalAcctValue?: number
  fileDate?: string
  headerDate?: string
  account?: {
    partyID?: string
    accountTitle?: string
    registrationType?: string
    shortName?: string
    establishDate?: string
    party?: ICDMv2Party
  }
  originalOwner?: ICDMv2Party
  address?: {
    addressLine1?: string
    addressLine2?: string
    city?: string
    postalCode?: string
    state?: string
  }
  teamName?: string
  primaryCA?: string
  activeFlag?: string
}

export const getRMDDetails = (
  options: IApiOptions,
  request?: IOdataRequest
) => {
  const { apiRoot, cancelToken, accessToken } = options

  const query = request && `?${constructOdataQuery(request)}`
  const url = `${apiRoot}/datahub/RMDDetailsByAccount${query}`

  return axios
    .get<IOdataResult<IRMDDetailsResult>>(url, {
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${accessToken}`
      },
      cancelToken: cancelToken
    })
    .then((x) => x.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      throw e
    })
}

export const getHouseholdVisibility = (options: IApiOptions) => {
  const { apiRoot, cancelToken, accessToken } = options

  return axios
    .get<string[]>(
      `${apiRoot}/datahub/users/me/boardMemberHouseholdVisibility`,
      {
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${accessToken}`
        },
        cancelToken: cancelToken
      }
    )
    .then((x) => x.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}

export interface IUpcomingCapitalCall {
  capitalCallId?: string
  accountNumber?: string
  cusip?: string
  clientAdvisorId?: string
  advisorName?: string
  householdId?: string
  householdName?: string
  legalEntityid?: string
  legalEntityname?: string
  description?: string
  productSubType?: string
  assetClassDetailL3?: string
  vintageYear?: string
  committedCapital?: number
  capitalCallAmount?: number
  cashAmountDue?: number
  accountCashBalance?: number
  marginAgreementOnFile?: string
  capitalCallReceivedDate?: string
  nfsDueDate?: string
  sponsorDueDate?: string
  capitalCallStatus?: string
  settleDate?: string
  daysPastDue?: number
  pastDueReason?: string | null
  overrideStatus?: string
  notes?: string
  tradeStatusCode?: string
  tradeMainframeStatus?: string
  originalTradeDate?: string
  unfundedCommitment?: number
  cashAvailableAfterCall?: number
}
export interface ITransfer {
  accountNumber?: string
  cusipNumber?: string
  productName?: string
  quantity?: number
  settleDate?: string
  transactionType: string
  transferDate?: string
  transferId?: number
  transferStatus?: string
  legalEntityName?: string
  advisorId?: string
  advisorName?: string
  comments?: string
}

export const getUpcomingCapitalCalls = (
  options: IApiOptions,
  request: IOdataRequest
) => {
  const { apiRoot, cancelToken, accessToken } = options

  const url = `${apiRoot}/datahub/UpcomingCapitalCalls?${constructOdataQuery(
    request
  )}`

  return axios
    .get<IOdataResult<IUpcomingCapitalCall>>(url, {
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${accessToken}`
      },
      cancelToken: cancelToken
    })
    .then((x) => x.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      throw e
    })
}
export const getTransfers = (options: IApiOptions, request: IOdataRequest) => {
  const { apiRoot, cancelToken, accessToken } = options

  const url = `${apiRoot}/datahub/AiTransfers?${constructOdataQuery(request)}`

  return axios
    .get<IOdataResult<ITransfer>>(url, {
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${accessToken}`
      },
      cancelToken: cancelToken
    })
    .then((x) => x.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      throw e
    })
}

// AnnuityPPLIContracts

export interface IAnnuityPPLIContract {
  contractType?: string
  householdName?: string
  accountNumber?: string
  legalEntityName?: string
  contractNumber?: string
  cusipNumber?: string
  securityDescription?: string
  partyState?: string
  productType?: string
  carrierName?: string
  cleanCarrierName?: string
  issueDate?: string
  contractStatusDesc?: string
  dollarAmountContributed?: number
  valuationDate?: string
  cashValue?: number
  deathBenefitAmt?: number
  surrenderValue?: number
  surrenderCharge?: number
  surrenderDate?: string
  rmdAmount?: number
  maturityDate?: string
  loanAmount?: number
  insuredAnnuitants?: string
  productName?: string
  partyAddressLine1?: string
  partyAddressLine2?: string
  partyCity?: string
  partyPostalCode?: string
  highBenefitName?: string
  maxBenefit?: number
  incomeBenefitAmt?: number
  feeType?: string
  tbfPercentAmt?: number
  agents?: string
  clientName?: string
  teamName?: string
  assetClassL1?: string
  assetClassL2?: string
  assetClassL3?: string
  assetClassL4?: string
  assetType?: string
  assetSubType?: string
  registeredRep?: string
  registeredRepName?: string
  regionName?: string
  // Insurance props
  comdex?: string
  annualizedPremium?: number
  premiumAmount?: number
  premiumPaidSinceInception?: number
  premiumDate?: string
  beneficiaryName?: string
  manuallyLinkedPartyId?: string
}

export const getAnnuityPPLIContracts = (
  options: IApiOptions,
  request: IOdataRequest
) => {
  const { apiRoot, cancelToken, accessToken } = options

  const url = `${apiRoot}/datahub/AnnuityPPLIContracts?${constructOdataQuery(
    request
  )}`

  return axios
    .get<IOdataResult<IAnnuityPPLIContract>>(url, {
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${accessToken}`
      },
      cancelToken: cancelToken
    })
    .then((x) => x.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      throw e
    })
}

// AnnuityPPLIAssets

interface IContractSecurity {
  rcmSecId?: string
  securityDescription?: string
  assetType?: string
  assetSubType?: string
  l1Description?: string
  l2Description?: string
  l3Description?: string
  l4Description?: string
  cusip?: string
}

export interface IAnnuityPPLIAssets {
  contractType?: string
  householdName?: string
  accountNumber?: string
  legalEntityName?: string
  contractNumber?: string
  description?: string
  productType?: string
  carrierName?: string
  cleanCarrierName?: string
  assetCusip?: string
  assetDescription?: string
  assetClassL1?: string
  assetSubType?: string
  marketValue?: number
  fundPercent?: number
  assetClassL2?: string
  assetClassL3?: string
  assetClassL4?: string
  assetType?: string
  teamName?: string
  registeredRep?: string
  registeredRepName?: string
  contract?: IAnnuityPPLIContract
  contractSecurity?: IContractSecurity
  regionName?: string
}

export const getAnnuityPPLIAssets = (
  options: IApiOptions,
  request: IOdataRequest
) => {
  const { apiRoot, cancelToken, accessToken } = options

  const url = `${apiRoot}/datahub/AnnuityPPLIAssets?${constructOdataQuery(
    request
  )}`

  return axios
    .get<IOdataResult<IAnnuityPPLIAssets>>(url, {
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${accessToken}`
      },
      cancelToken: cancelToken
    })
    .then((x) => x.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      throw e
    })
}

export interface IAltsAdvisorRevenueDetail {
  planId?: number
  cusip?: string
  fundName?: string
  partyType?: string
  shortName?: string
  teamName?: string
  repName?: string
  accountNumber?: string
  custodianRepNo?: string
  advisorRepNo?: string
  amount?: number
  agingMonth?: Date
  revenueType?: string
  revenueMonth?: Date
  feeTypeCode?: string
}

export const getAltsAdvisorRevenueDetail = (
  options: IApiOptions,
  request: IOdataRequest
) => {
  const { apiRoot, cancelToken, accessToken } = options

  const url = `${apiRoot}/datahub/AltsAdvisorRevenueDetail?${constructOdataQuery(
    request
  )}`

  return axios
    .get<IOdataResult<IAltsAdvisorRevenueDetail>>(url, {
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${accessToken}`
      },
      cancelToken: cancelToken
    })
    .then((x) => x.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      throw e
    })
}

export const getActivity = (options: IApiOptions, request: IOdataRequest) => {
  const { apiRoot, cancelToken, accessToken } = options

  const url = `${apiRoot}/datahub/ActivityAgg?${constructOdataQuery(request)}`

  return axios
    .get<IOdataResult<IActivity>>(url, {
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${accessToken}`
      },
      cancelToken: cancelToken
    })
    .then((x) => x.data)
}
