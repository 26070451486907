import { lazy } from 'react'
import { IModuleDefinition } from '../types'

const OTPContainer = lazy(() => import('./OTPContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/clientverification',
    private: true,
    component: OTPContainer
  },
  name: 'Client Verification'
}

export default moduleDefinition
