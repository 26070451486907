import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { INfsProfile, IRelatedParty } from 'api/datahub'
import { constructOdataQuery, escapeFilterString } from 'api/odata'
import { IOdataRequest } from 'api/odata.types'
import { IPerformanceReportLog } from 'api/performancereport.types'
import { tokenizeQuery, escapeAndEncodeQuery } from 'api/search'
import { isString } from 'lodash'
import { flow } from 'lodash/fp'
import { IOdataResult } from 'shared/contracts/IOdataResult'
import { tryAcquireAccessToken } from 'shared/services/auth'
import { AppState } from 'store/shared'
import { getRockefellerApiConfig } from 'store/system'
import { arrayCommaParamsSerializer, axiosBaseQuery } from '../shared'
import {
  IEnhancedClientProfileResponse,
  INfsProfileWithPilotInfo
} from './IEnhancedClientProfile'

export const DatahubApiSliceKey = 'api.datahub'
export type DatahubApiReducerState = {
  [DatahubApiSliceKey]: ReturnType<typeof datahubApi.reducer>
}

const getDatahubApiBaseUrl = (state: AppState) => {
  const base = flow(getRockefellerApiConfig, (x) => x?.root)(state)
  const v1 = new URL('datahub', base)
  return v1.href
}

const getDatahubApiAuthToken = (state: AppState) => {
  const scopes = flow(getRockefellerApiConfig, (x) => x?.scopes)(state)

  if (!scopes) {
    throw new Error('')
  }

  return tryAcquireAccessToken(scopes)
}

export const constructQuery = (query: string) =>
  tokenizeQuery(query)
    .map(escapeFilterString)
    .map(escapeAndEncodeQuery)
    .filter(Boolean)
    .map((x) => `${x}*`)
    .join(' AND ')

export const datahubApi = createApi({
  reducerPath: DatahubApiSliceKey,
  baseQuery: axiosBaseQuery({
    getBaseUrl: (state) => getDatahubApiBaseUrl(state),
    getAuthToken: (state) => getDatahubApiAuthToken(state)
  }),
  tagTypes: ['profile', 'performancelogs'],
  endpoints: (builder) => ({
    getPerformanceReportsLogs: builder.query<
      IPerformanceReportLog[] | undefined,
      IOdataRequest
    >({
      query: (req) => ({
        url: 'performanceReportLogs?' + constructOdataQuery(req)
      }),
      transformResponse: (response: IOdataResult<IPerformanceReportLog>) =>
        response?.value,
      providesTags: () => [{ type: 'performancelogs' }]
    }),
    fetchNfsProfiles: builder.query<INfsProfile[] | undefined, IOdataRequest>({
      query: (req) => ({
        url: 'nfsProfiles?' + constructOdataQuery(req)
      }),
      transformResponse: (response: IOdataResult<INfsProfile>) =>
        response?.value
    }),
    fetchNfsProfilesByPartyId: builder.query<INfsProfile[] | undefined, string>(
      {
        query: (id) => ({
          url: 'nfsProfiles',
          params: {
            $filter: `Partyid eq '${id}'`,
            select: [
              'id',
              'hasAnyMfaMethods',
              'isEnrolledInEauth',
              'lastlogindate',
              'loginid',
              'wsportaluserid',
              'accounts'
            ]
          },
          paramsSerializer: arrayCommaParamsSerializer,
          headers: { 'api-version': '1.0' }
        }),
        transformResponse: (response: IOdataResult<INfsProfile>) =>
          response?.value,
        providesTags: (_result, _error, id) => [{ type: 'profile', id }]
      }
    ),
    fetchNfsProfileById: builder.query<
      INfsProfile | undefined,
      string | { id: string | number; select?: string[] }
    >({
      query: (args) => {
        const { id, select } = isString(args)
          ? { id: args, select: undefined }
          : args
        return {
          url: `/nfsProfiles/${id}`,
          params: { select },
          paramsSerializer: arrayCommaParamsSerializer,
          headers: { 'api-version': '1.0' }
        }
      },
      providesTags: (result) => [{ type: 'profile', id: result?.id }]
    }),
    fetchEnhancedProfileByWealthscapeId: builder.query<
      IEnhancedClientProfileResponse | undefined,
      string
    >({
      query: (wsPortalUserId) => ({
        method: 'POST',
        url: `/nfsProfiles/GetEnhancedProfile`,
        data: {
          request: {
            wsPortalUserId: wsPortalUserId
          }
        },
        headers: { 'api-version': '1.0' }
      }),
      providesTags: (result) => [{ type: 'profile', id: result?.profile.id }]
    }),
    fetchClientsPilotInfo: builder.query<
      INfsProfileWithPilotInfo[] | undefined,
      number[]
    >({
      query: (ids) => ({
        method: 'GET',
        url: `/nfsProfiles`,
        params: {
          $filter: `id in (${ids.join(',')})`,
          $select: 'multiCustodianPilotInfo'
        },
        headers: { 'api-version': '1.0' }
      }),
      transformResponse: (response: IOdataResult<INfsProfileWithPilotInfo>) =>
        response?.value
    }),
    getRelatedPartiesByHouseholdId: builder.query<
      IRelatedParty[] | undefined,
      string
    >({
      query: (householdId) => ({
        url: `/relatedparties/Household/${householdId}`
      }),
      keepUnusedDataFor: 60 * 20
    }),
    getRelatedPartiesByAccountId: builder.query<
      IRelatedParty[] | undefined,
      string
    >({
      query: (accountId) => ({
        url: `/relatedparties/Account/${accountId}`
      }),
      keepUnusedDataFor: 60 * 20
    }),
    getRelatedPartiesByPartyId: builder.query<
      IRelatedParty[] | undefined,
      string
    >({
      query: (partyId) => ({
        url: `/relatedparties/Party/${partyId}`
      }),
      keepUnusedDataFor: 60 * 20
    })
  })
})
export const {
  useFetchNfsProfileByIdQuery,
  useFetchNfsProfilesQuery,
  useFetchNfsProfilesByPartyIdQuery,
  useLazyFetchNfsProfileByIdQuery,
  useFetchEnhancedProfileByWealthscapeIdQuery,
  useFetchClientsPilotInfoQuery,
  useGetRelatedPartiesByHouseholdIdQuery,
  useGetRelatedPartiesByAccountIdQuery,
  useGetPerformanceReportsLogsQuery
} = datahubApi
