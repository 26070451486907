import { IPerformanceReportLog } from 'api/performancereport.types'
import { format } from 'date-fns'
import { groupBy, reverse, sortBy } from 'lodash'
import { useMemo } from 'react'
import { isValidDate } from 'shared/dates'
import { useGetPerformanceReportsLogsQuery } from 'store/api/datahub'

const getPerformanceReportsLogsPayload = (fileId: string) => {
  return {
    count: true,
    select: ['createdOn', 'requestedBy', 'shareWithUser'],
    filters: [`sourceSystemId eq '${fileId}'`, "logType eq 'INFO'"]
  }
}

const getLogsGroupedSorted = (data: IPerformanceReportLog[]) => {
  if (!data.length) {
    return []
  }
  // map the data to format the date or mark it as invalid
  const logsMapped = data.map((log) => {
    const { createdOn = '' } = log
    const createdOnDate = isValidDate(createdOn)
      ? format(createdOn, 'yyyy-MM-dd')
      : 'Invalid Date'
    return {
      ...log,
      createdOn: createdOnDate
    }
  })
  // Group by created-on date (formatted yyyy-MM-dd) and person requested-by
  const logsGrouped = groupBy(
    logsMapped,
    (log) => `${log.createdOn}-${log.requestedBy}`
  )
  // convert grouped object into array
  const logsGroupedArray = Object.entries(logsGrouped)
  // Sort the array by createdOn descending
  const logsGroupedSorted = reverse(
    sortBy(logsGroupedArray, [
      (group) => {
        return group[1][0].createdOn
      }
    ])
  )

  return logsGroupedSorted
}

export const useShareReportLogic = (fileId: string) => {
  const performanceReportsLogsPayload = getPerformanceReportsLogsPayload(fileId)

  const {
    data: reportsLogsData,
    error,
    isFetching
  } = useGetPerformanceReportsLogsQuery(performanceReportsLogsPayload)

  const dataOnlyWhenSuccessful = useMemo(() => {
    const empty: IPerformanceReportLog[] = []
    return error ? empty : reportsLogsData || empty
  }, [reportsLogsData, error])

  const showNoData = useMemo(
    () => !dataOnlyWhenSuccessful?.length && !isFetching,
    [dataOnlyWhenSuccessful?.length, isFetching]
  )

  const calloutPlaceholder = useMemo(() => {
    if (isFetching) {
      return 'Loading data...'
    }
    if (showNoData) {
      return 'No logs available'
    }

    return null
  }, [isFetching, showNoData])

  const logsGroupedSorted = useMemo(
    () => getLogsGroupedSorted(dataOnlyWhenSuccessful),
    [dataOnlyWhenSuccessful]
  )

  return {
    calloutPlaceholder,
    logsGroupedSorted
  }
}
