import { css } from '@emotion/react'
import {
  Checkbox,
  DefaultButton,
  MessageBar,
  MessageBarType,
  Overlay,
  Panel,
  PanelType,
  PrimaryButton,
  SearchBox,
  Stack,
  Text,
  TextField
} from '@fluentui/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { LoadingComponent } from 'shared/components/Loading'
import { Icon } from 'shared/uiSharedComponents/Icon'
import { useEditHouseholdTags } from '../hooks/useEditHouseholdTags'
import { IHouseholdTagName } from '../store/editHouseholdTagsApi'

const classes = {
  container: css({
    alignItems: 'center',
    '&:hover div.edit-icon': {
      display: 'block'
    }
  }),
  hover: css({
    cursor: 'pointer',
    display: 'none'
  }),
  linkButton: css({
    background: 'none',
    border: 'none',
    textDecoration: 'underline',
    color: '#2E536F',
    cursor: 'pointer',
    '&:disabled': {
      color: '#BAB8B7',
      cursor: 'default'
    }
  })
}

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const EditHouseholdTagsPanel: React.FC = () => {
  const {
    addTagName,
    editTagName,
    closePanel,
    refetchTagNames,
    refreshHouseholdList,
    updateTags,
    addTagNameLoading,
    addTagNameSuccess,
    canEditSystemTags,
    editTagNameLoading,
    editTagNameSuccess,
    errorMessage,
    isLoading,
    isPanelOpen,
    household,
    tagNames,
    tags,
    updateTagsLoading
  } = useEditHouseholdTags()

  const [selectedTagIds, setSelectedTagIds] = useState<number[]>([])

  const [editTag, setEditTag] = useState<IHouseholdTagName | undefined>()
  const onEditTagChange = useCallback(
    (_: unknown, newValue?: string) => {
      if (editTag) {
        setEditTag({ ...editTag, tagName: newValue || '' })
      }
    },
    [editTag]
  )

  const [newTagName, setNewTagName] = useState<string>()
  const onNewTagNameChange = useCallback((_: unknown, newValue?: string) => {
    setNewTagName(newValue)
  }, [])

  const [searchText, setSearchText] = useState<string | undefined>()
  const onSearchChange = useCallback((_: unknown, newValue?: string) => {
    setSearchText(newValue)
  }, [])

  const [tagsAdded, tagsRemoved] = useMemo(() => {
    const tagIds = tags?.map((t) => t.tagId) ?? []
    const added = selectedTagIds.filter((t) => !tagIds.includes(t))
    const removed = tagIds.filter((t) => !selectedTagIds.includes(t))

    return [added, removed]
  }, [selectedTagIds, tags])

  const visibleTags = useMemo(() => {
    if (!tagNames) {
      return
    }

    const lowerCaseSearchText = (searchText || '').trim().toLowerCase()
    const filtered = lowerCaseSearchText
      ? tagNames.filter(
          (t) => t.tagName.toLocaleLowerCase().indexOf(lowerCaseSearchText) >= 0
        )
      : tagNames

    return filtered
  }, [searchText, tagNames])

  useEffect(() => {
    if (!addTagNameSuccess) {
      return
    }
    setNewTagName('')
    refetchTagNames()
  }, [addTagNameSuccess, refetchTagNames])

  useEffect(() => {
    if (!editTagNameSuccess) {
      return
    }
    setEditTag(undefined)
    refetchTagNames()
  }, [editTagNameSuccess, refetchTagNames])

  useEffect(() => {
    if (isPanelOpen) {
      setSelectedTagIds(tags?.map((t) => t.tagId) ?? [])
    } else {
      setEditTag(undefined)
      setNewTagName('')
      setSearchText('')
      setSelectedTagIds([])
    }
  }, [isPanelOpen, tags])

  const onSubmit = useCallback(async () => {
    const result = await updateTags(tagsAdded, tagsRemoved)
    if (result) {
      closePanel()
      await delay(500)
      refreshHouseholdList()
    }
  }, [closePanel, refreshHouseholdList, tagsAdded, tagsRemoved, updateTags])

  const onRenderFooterContent = useCallback(() => {
    const canSubmit =
      (tagsAdded.length || tagsRemoved.length) &&
      !editTag &&
      !isLoading &&
      !addTagNameLoading &&
      !editTagNameLoading &&
      !updateTagsLoading
    return (
      <Stack tokens={{ childrenGap: 10 }}>
        {errorMessage && (
          <MessageBar messageBarType={MessageBarType.error}>
            An error occurred while submitting the request: {errorMessage}
          </MessageBar>
        )}
        <Stack
          horizontal={true}
          tokens={{ childrenGap: 5 }}
          verticalAlign="end"
        >
          <Stack.Item grow={1}>
            <TextField
              autoComplete="off"
              disabled={addTagNameLoading}
              maxLength={50}
              onChange={onNewTagNameChange}
              placeholder="Add a new tag"
              value={newTagName}
            />
          </Stack.Item>
          <Stack.Item>
            <DefaultButton
              disabled={!newTagName?.length || addTagNameLoading}
              onClick={() => addTagName(newTagName)}
            >
              {addTagNameLoading ? <LoadingComponent /> : 'Add'}
            </DefaultButton>
          </Stack.Item>
        </Stack>
        <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
          <PrimaryButton onClick={onSubmit} disabled={!canSubmit}>
            {updateTagsLoading ? <LoadingComponent /> : 'Submit'}
          </PrimaryButton>
          <DefaultButton onClick={closePanel}>Cancel</DefaultButton>
        </Stack>
      </Stack>
    )
  }, [
    addTagName,
    addTagNameLoading,
    closePanel,
    editTag,
    editTagNameLoading,
    errorMessage,
    isLoading,
    newTagName,
    onNewTagNameChange,
    onSubmit,
    tagsAdded.length,
    tagsRemoved.length,
    updateTagsLoading
  ])

  const onRenderHeader = useCallback(
    () => (
      <div
        style={{
          alignSelf: 'flex-start',
          flexGrow: 1,
          padding: '0 0 20px 24px'
        }}
      >
        <Stack>
          <Text variant="xLarge" styles={{ root: { fontWeight: 'bold' } }}>
            Edit Household Tags
          </Text>
          {!!household && (
            <Text variant="small">{household.householdName}</Text>
          )}
        </Stack>
      </div>
    ),
    [household]
  )

  const handleCheckboxChange = useCallback(
    (checked: boolean, tagId: number) => {
      const selected = [...selectedTagIds]
      const updated = checked
        ? [...selected, tagId]
        : (() => {
            selected.splice(selected.indexOf(tagId), 1)
            return selected
          })()
      setSelectedTagIds(updated)
    },
    [selectedTagIds]
  )

  return (
    <Panel
      isOpen={isPanelOpen}
      onDismiss={closePanel}
      closeButtonAriaLabel="Close"
      customWidth="400px"
      onRenderFooterContent={onRenderFooterContent}
      onRenderHeader={onRenderHeader}
      type={PanelType.custom}
    >
      <Stack tokens={{ childrenGap: 10 }} verticalFill={true} grow={1}>
        <SearchBox
          disabled={!!editTag}
          placeholder="Filter Tags"
          value={searchText}
          onChange={onSearchChange}
          autoComplete="off"
        />
        {visibleTags
          ? visibleTags.map((t) => (
              <Stack
                css={classes.container}
                horizontal={true}
                key={t.tagId}
                tokens={{ childrenGap: 10 }}
              >
                {editTag?.tagId === t.tagId ? (
                  <>
                    <TextField
                      maxLength={50}
                      value={editTag.tagName}
                      onChange={onEditTagChange}
                    />
                    <div css={{ display: 'flex', alignItems: 'center' }}>
                      <Icon
                        type="CancelClose"
                        height={10}
                        width={10}
                        color="#0073e6"
                      />
                      <button
                        css={classes.linkButton}
                        onClick={() => setEditTag(undefined)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div css={{ display: 'flex', alignItems: 'center' }}>
                      <Icon
                        type="SaveOkDone"
                        height={12}
                        width={12}
                        color="#0073e6"
                      />
                      <button
                        css={classes.linkButton}
                        disabled={
                          editTag.tagName.length === 0 || editTagNameLoading
                        }
                        onClick={() => editTagName(editTag)}
                      >
                        Save
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <Checkbox
                      checked={selectedTagIds.includes(t.tagId)}
                      disabled={
                        (t.entityId == null && !canEditSystemTags) || !!editTag
                      }
                      label={t.tagName}
                      onChange={(_ev, checked) =>
                        handleCheckboxChange(checked || false, t.tagId)
                      }
                    />
                    {(t.entityId != null ||
                      (t.entityId == null && canEditSystemTags)) && (
                      <div className="edit-icon" css={classes.hover}>
                        <Icon
                          type="Edit"
                          height={16}
                          width={16}
                          color="#0073e6"
                          title="Edit Tag"
                          onClick={() => setEditTag(t)}
                        />
                      </div>
                    )}
                  </>
                )}
              </Stack>
            ))
          : !isLoading && (
              <MessageBar messageBarType={MessageBarType.info}>
                No Tags Available
              </MessageBar>
            )}
      </Stack>
      {isLoading && (
        <Overlay styles={{ root: { top: 75, zIndex: 11 } }}>
          <LoadingComponent />
        </Overlay>
      )}
    </Panel>
  )
}
