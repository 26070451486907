import { css, useTheme } from '@emotion/react'
import { Searchbox } from 'modules/Advisory/modules/Rdot360/components/shared/DetailTables/Searchbox'
import { Icon } from 'modules/Advisory/modules/Rdot360/features/Icons/Icon'
import { useCallback, useMemo } from 'react'
import AccountToggle from '../../features/Performance/AccountToggle'
import IncomeReturnTypeToggle from '../../features/Performance/IncomeReturnTypeToggle'
import PerformanceDateRangeFilter from '../../features/Performance/PerformanceDateRangeFilter'
import PerformanceTypeFilter from '../../features/Performance/PerformanceTypeFilter'
import { usePerformanceState } from '../../features/Performance/store'
import { useDatahubApiUtil } from '../../store/datahub'
import { useRdot360PerformanceContext } from '../../store/rdot360Context/useRdot360PerformanceContext'

export const getClasses = () => ({
  filterContainer: css({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    columnGap: 16,
    rowGap: 10
  })
})

const PerformanceFilters: React.FC = () => {
  const classes = useMemo(() => getClasses(), [])
  const { searchText, setSearchText } = usePerformanceState()
  const { isGroupDataAvailable } = useRdot360PerformanceContext()
  const { invalidateTags } = useDatahubApiUtil()
  const invalidateCache = useCallback(
    () => invalidateTags(['performance']),
    [invalidateTags]
  )
  const theme = useTheme()
  return (
    <div css={classes.filterContainer}>
      <PerformanceDateRangeFilter />
      <div css={{ width: 170 }}>
        <PerformanceTypeFilter />
      </div>
      <IncomeReturnTypeToggle />
      {isGroupDataAvailable && <AccountToggle />}
      <Searchbox searchText={searchText} onChange={setSearchText} />
      <div
        css={{
          width: 24,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <Icon
          type="Refresh"
          color={theme.colors.extraBlue2}
          onClick={invalidateCache}
        />
      </div>
    </div>
  )
}

export default PerformanceFilters
